<template>
	<div class="app-container ExportResume">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">导入简历</div>
		</div>
		<el-form inline :model="exportForm" label-width="200px" class="exportForm">
			<el-row>
				<el-col :span="12">
					<el-form-item label="选择岗位">
						<el-select v-model="exportForm.jobId" placeholder="请选择">
							<el-option v-for="(job, i) in jobArr" :key="i" :label="job.jobName" :value="job.jobId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="计划名称">
						<el-input v-model="exportForm.planName" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="上传至">
						<el-select v-model="exportForm.status" placeholder="请选择">
							<el-option v-for="(status, i) in statusArr" :key="i" :label="status.label" :value="status.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="选择渠道">
						<el-select v-model="exportForm.sources" placeholder="请选择">
							<el-option v-for="(sources, i) in sourcesArr" :key="i" :label="sources.dictName" :value="sources.dictId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="简历">
						<el-upload
							class="upload-demo"
							action
							:show-file-list="false"
							:http-request="exportExcel"
							:multiple="true"
							:limit="10"
							accept=".DOC, .DOCX,.XLSX,.XLX,.pdf"
						>
							<el-button type="primary" icon="el-icon-upload">上传</el-button>
						</el-upload>
						<el-button type="primary" icon="el-icon-download" @click="_download">下载模板</el-button>
					</el-form-item>
					<!--<div class="import_dialog_desc">
                <span
                    v-show="errShow"
                    style="color:red"
                    class="import_dialog_desc_download_text"
                    @click="downLoadError"
                >*上传失败，请点击这里下载查看</span>
            </div>!-->
				</el-col>
				<!--<el-col :span="18">
            <el-form-item label=" ">
                <el-button type="text" @click="uploadrequest=true">上传要求</el-button>
            </el-form-item>
          </el-col>!-->
			</el-row>
		</el-form>
		<el-dialog title="上传要求" :visible.sync="uploadrequest" class="uploaddig" width="40%" center>
			<ol>
				<li>每次最多可上传10份简历</li>
				<li>只支持.xls和.xlsx两种格式</li>
				<li>保持模板样式，不能更改sheet顺序，及其名称</li>
				<li>第一个sheet中，姓名与手机号必须唯一，且不可为空。其他sheet与之保持一致，可填写多组信息。比如“工作经历”，可填写2条以上记录</li>
				<li>选项类信息，请使用模板中选项，比如“性别，只能使用模板中提供的“未知”、“男”、“女”</li>
				<li>请不要加密模板文件，将导致错误</li>
				<li>请不要上传带宏的文件，将导致错误</li>
				<li>请不要过多的使用格式，如背景色和边框，将可能导致超时</li>
				<li>示例行的内容无需删除，将不会上传到系统中</li>
			</ol>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="uploadrequest = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { recruitStationList } from '../../../api/recruitment_allocation';
export default {
	data() {
		return {
			exportForm: {
				jobId: '',
				planName: '',
				status: '',
				sources: '',
				recruitId: '',
				planId: ''
			},
			jobArr: [],
			statusArr: [
				{ value: '1', label: '简历管理' },
				{ value: '2', label: '备选' }
			],
			sourcesArr: [],
			errShow: true,
			importUrl: '/v1/recruit/resume/import',
			uploadrequest: false,
			downloadUrl: []
		};
	},
	watch: {
		'exportForm.jobId': {
			handler(val) {
				if (val) {
					this.jobToplan(val);
				}
			},
			deep: true
		}
	},
	methods: {
		exportExcel(event) {
			for (const key in this.exportForm) {
				if (this.exportForm[key] == '' || !this.exportForm[key]) {
					return this.$message.error('请选择简历导入所需要的选项');
				}
			}
			this.errShow = false;
			if (!event.file || !event.file.name) {
				this.$message.error('文件有误');
				return;
			}
			// if (['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',"application/vnd.ms-excel"].indexOf(event.file.type) == -1) {
			//     this.$message.error('请上传正确Excel文件')
			//     return
			// }
			const params = new FormData();

			params.append('imgFile', event.file);
			params.append('object', JSON.stringify(this.exportForm));
			const loading = this.$loading({
				lock: true,
				text: '正在努力导入......',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loadClass'
			});

			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.$axios
				.post(this.importUrl, params, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					if (res) {
						if (res.errorDataUrl) {
							this.errShow = true;
							this.errUrl = res.errorDataUrl;
						} else if (res._responseStatusCode === 0) {
							loading.spinner = 'el-icon-success';
							loading.text = '导入成功';
							this.$router.push({ name: 'Distinguish', params: { detail: res } });
						}
						setTimeout(() => {
							loading.close();
						}, 1000);
					}
				});
			this.fileList = [];
		},
		getJob() {
			recruitStationList({ status: '1', pageNo: 1, pageSize: 9999 }).then((res) => {
				if (res._responseStatusCode == 0) {
					res.list.forEach((it) => {
						this.jobArr.push({ recruitId: it.id, ...it });
					});
				}
			});
		},
		jobToplan(val) {
			this.jobArr.map((it) => {
				if (it.jobId == val) {
					this.exportForm.recruitId = it.recruitId;
					this.exportForm.planName = it.planName;
					this.exportForm.planId = it.planId;
				}
			});
		},
		_download() {
			if (!this.exportForm.sources) {
				this.$message.warning('请您先选择需要下载的模板来源渠道');
				return;
			}
			this.sourcesArr.map((it) => {
				this.downloadUrl.map((it1) => {
					if (this.exportForm.sources == it.dictId && it.dictName == it1.dictName) {
						window.open(it1.remark);
					}
				});
			});
		}
	},
	created() {
		this.sourcesArr = this.$store.state.app.dict.filter((item) => item.groupId == 'fromType');
		this.downloadUrl = this.$store.state.app.dict.filter((item) => item.groupId == 'resumeTemplate');
		console.log(this.downloadUrl, this.sourcesArr);
		this.getJob();
	}
};
</script>
<style lang="scss">
.ExportResume {
	.exportForm {
		background: #fff;
		padding: 20px 30px;
		.el-select,
		.el-input {
			width: 280px;
		}
	}
	.upload-demo {
		display: inline-block;
		margin-right: 10px;
	}
	.import_dialog_desc {
		padding-left: 200px;
	}
	.uploaddig {
		.el-dialog__body {
			padding-bottom: 0;
		}
		ol {
			list-style: decimal;
			padding: 0 30px;
			li {
				margin: 10px 0;
				line-height: 18px;
			}
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
